<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <div
          v-if="estimateLine.external === false"
          class="grid-x grid-padding-x">
          <div class="cell small-12 medium-8">
            <label>Select personnel</label>
            <select v-model="estimateLine.rate_id">
              <optgroup
                :label="rate.domain"
                v-for="(rate, index) in rateOptions"
                :key="`r_${index}`">
                <option
                  v-for="(r, index) in rate.rates"
                  :key="`rr_${index}`"
                  :value="r.rateId">
                  {{ r.jobTitle }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
        <div
          v-else
          class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-8">
            <label>Enter description</label>
            <input type="text" v-model="estimateLine.description">
          </div>
        </div>
        <div
          class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-8">
            <label>Cost Domain</label>
            <select v-model="estimateLine.cost_domain_id">
              <option
                v-for="(domain, index) in costDomains"
                :key="`d_${index}`"
                :value="domain.id">
                {{ domain.domain }}
              </option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-8">
            <label v-if="estimateLine.external === false">Hourly Rate</label>
            <label v-else>Price per unit</label>
            <input
              v-model="estimateLine.cost_each"
              type="number"
              min="0"
              step="0.01" />
          </div>
        </div>
        <div
          v-if="estimateLine.external === false"
          class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-4">
            <label>Hours</label>
            <input type="number" min="0" step="1" v-model="estimateLine.hours" />
          </div>
          <div class="cell small-12 medium-4">
            <label>Minutes</label>
            <select v-model="estimateLine.minutes">
              <option valu="0">0</option>
              <option valu="15">15</option>
              <option valu="15">30</option>
              <option valu="15">45</option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-8">
            <label>Total</label>
            <input type="number" :value="total" disabled />
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <button @click="saveUpdate()" class="button">Save</button>
        &nbsp;
        <button @click="$emit('closeDialogue')" class="button red">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'EditLine',
  props: [
    'costDomains',
    'estimate',
    'estimateLineId',
    'rateOptions',
    'rates',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    total() {
      if (this.estimateLine.external === true) {
        return this.estimateLine.quantity * this.estimateLine.cost_each;
      }
      // eslint-disable-next-line
      const minutes = parseInt(this.estimateLine.minutes);
      // eslint-disable-next-line
      const hours = parseInt(this.estimateLine.hours);
      let multiplier = 0;
      if (minutes === 15) multiplier = 0.25;
      if (minutes === 30) multiplier = 0.5;
      if (minutes === 45) multiplier = 0.75;
      multiplier += hours;
      return multiplier * this.estimateLine.cost_each;
    },
  },
  data() {
    return {
      estimateLine: {},
    };
  },
  methods: {
    saveUpdate() {
      const postData = {};
      postData.estimateLine = this.estimateLine;
      axios.post(`/estimateLines/update.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('lineUpdated', response.data.estimateLine);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getLine() {
      axios.get(`/estimateLines/getOne/${this.estimateLineId}.json?token=${this.token}`)
        .then((response) => {
          this.estimateLine = response.data.estimateLine;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getLine();
    }, 100);
  },
};
</script>
