<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>Revise Estimate</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link :to="`/estimates/view/${estimate.id}`">
          <button class="button expanded">View Estimate</button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <p>
          Client: {{ estimate.client.name }}<br />
          Brand: {{ estimate.brand.name }}<br />
          Job Number: {{ estimate.job_number.job_number }}<br />
          Job Description: {{ estimate.job_description }}<br />
          Created by: {{ estimate.user.first_name }} {{ estimate.user.last_name }}
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-10">
          <h4>Estimate includes</h4>
          <p>
            <textarea @keyup="saveEstimate()" v-model="estimate.estimate_includes"></textarea>
          </p>
        </div>
      </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4>Existing lines</h4>
        <table>
          <thead>
            <tr>
              <th>Personnel/Description</th>
              <th>Time/Quantity</th>
              <th>Cost</th>
              <th>Total</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th colspan="3">
                Estimate total
              </th>
              <th>
                {{ estimate.subTotal | currency }}
              </th>
              <th>&nbsp;</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(line, index) in estimate.estimate_lines" :key="`l_${index}`">
              <td>
                <span v-if="line.external === false">{{ line.personnel }}</span>
                <span v-else>{{ line.description }}</span>
              </td>
              <td>
                <span v-if="line.external === true">{{ line.quantity }}</span>
                <span v-else>{{ line.hours }}:{{ line.minutes }}</span>
              </td>
              <td>{{ line.cost_each | currency }}</td>
              <td>
                <span v-if="line.external === true">{{ line.cost_each | currency }}</span>
                <span v-else>{{ line.lineValue | currency }}</span>
              </td>
              <td>
                <span
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="lineInEdit = line.id"
                  >edit</span>
                &nbsp;
                <span
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteLine(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4>Add a new line to this estimate</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label class="pb-1">Is this an internal or external cost?</label>
        <div class="switch large">
          <input
            v-model="newEstimateLine.external"
            class="switch-input"
            id="int-ext"
            type="checkbox"
            name="intExtSwitch">
          <label class="switch-paddle" for="int-ext">
            <span class="show-for-sr">External or Internal?</span>
            <span class="switch-active" aria-hidden="true">External</span>
            <span class="switch-inactive" aria-hidden="true">Internal</span>
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="newEstimateLine.external === false"
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Select personnel</label>
        <select v-model="rateId">
          <optgroup
            :label="rate.domain"
            v-for="(rate, index) in rateOptions"
            :key="`r_${index}`">
            <option
              v-for="(r, index) in rate.rates"
              :key="`rr_${index}`"
              :value="r.rateId">
              {{ r.jobTitle }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>
    <div
      v-else
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Enter description<sup>*</sup></label>
        <input required type="text" v-model="newEstimateLine.description">
      </div>
    </div>
    <div
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Cost Domain<sup>*</sup></label>
        <select
          required
          v-model="newEstimateLine.cost_domain_id">
          <option
            v-for="(domain, index) in costDomains"
            :key="`d_${index}`"
            :value="domain.id">
            {{ domain.domain }}
          </option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label v-if="newEstimateLine.external === false">Hourly Rate</label>
        <label v-else>Price per unit</label>
        <input
          v-model="newEstimateLine.cost_each"
          type="number"
          min="0"
          step="0.01" />
      </div>
    </div>
    <div
      class="grid-x grid-padding-x pt-5">
      <div v-if="newEstimateLine.external === false" class="cell small-12 medium-4">
        <label>Hours</label>
        <input type="number" min="0" step="1" v-model="newEstimateLine.hours" />
      </div>
      <div v-if="newEstimateLine.external === false" class="cell small-12 medium-4">
        <label>Minutes</label>
        <select v-model="newEstimateLine.minutes">
          <option valu="0">0</option>
          <option valu="15">15</option>
          <option valu="15">30</option>
          <option valu="15">45</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Total</label>
        <input type="number" :value="newLineTotal" disabled />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-2">
        <button @click="saveLine(false)" class="button expanded">Add Line</button>
      </div>
      <!--
        <div class="cell small-12 medium-2">
          <button @click="saveLine(true)" class="button expanded">Finish</button>
        </div>
      -->
    </div>
    <EditLine
      v-if="lineInEdit > 0"
      :estimateLineId="lineInEdit"
      @closeDialogue="lineInEdit = 0"
      @lineUpdated="updateLine"
    />
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import EditLine from './components/EditLine.vue';
import axios from '../../axios';

export default {
  name: 'EstimateRevise',
  components: {
    Message,
    EditLine,
  },
  data() {
    return {
      costDomains: [],
      estimate: {
        client: {},
        brand: {},
        job_number: {},
        user: {},
      },
      estimateTotal: 0,
      lineInEdit: 0,
      newEstimateLine: {
        cost_domain_id: 0,
        cost_each: 0,
        description: '',
        estimate_id: 0,
        external: false,
        personnel: '',
        quantity: 0,
      },
      rateId: 0,
      rates: [],
      rateOptions: [],
      message: '',
      showMessage: false,
    };
  },
  computed: {
    newLineTotal() {
      let total = 0;
      if (this.newEstimateLine.external === true) {
        total = this.newEstimateLine.cost_each;
      } else {
        let multiplier = 0;
        if (this.newEstimateLine.minutes === '15') multiplier = 0.25;
        if (this.newEstimateLine.minutes === '30') multiplier = 0.5;
        if (this.newEstimateLine.minutes === '45') multiplier = 0.75;
        multiplier += parseInt(this.newEstimateLine.hours, 10);
        total = multiplier * this.newEstimateLine.cost_each;
      }
      return total;
    },
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    rateId: {
      handler() {
        if (this.rateId > 0) {
          const rate = this.rates.filter((r) => r.id === this.rateId)[0];
          this.newEstimateLine.personnel = rate.job_title.title;
          this.newEstimateLine.cost_each = rate.hourly_rate;
          this.newEstimateLine.cost_domain_id = rate.job_title.cost_domain_id;
        }
      },
      deep: true,
    },
  },
  methods: {
    calcLineCost(line) {
      // eslint-disable-next-line
      const minutes = parseInt(this.line.minutes);
      // eslint-disable-next-line
      const hours = parseInt(this.line.hours);
      let multiplier = 0;
      if (minutes === 15) multiplier = 0.25;
      if (minutes === 30) multiplier = 0.5;
      if (minutes === 45) multiplier = 0.75;
      multiplier += hours;
      return multiplier * line.cost_each;
    },
    saveEstimate() {
      // eslint-disable-next-line
      clearTimeout(this._timerId);
      // eslint-disable-next-line
      this._timerId = setTimeout(() => {
        this.saveEstimateDebounced();
      }, 750);
    },
    saveEstimateDebounced() {
      const postData = {};
      postData.estimateId = this.estimate.id;
      postData.estimate_includes = this.estimate.estimate_includes;
      axios.post(`/estimates/updateIncludes.json?token=${this.token}`, postData);
    },
    updateLine(value) {
      this.lineInEdit = 0;
      for (let i = 0; this.estimate.estimate_lines.length > i; i += 1) {
        if (value.id === this.estimate.estimate_lines[i].id) {
          this.estimate.estimate_lines[i].cost_each = value.cost_each;
          this.estimate.estimate_lines[i].quantity = value.quantity;
        }
      }
    },
    createRevision() {
      const estimateId = this.$route.params.id;
      axios.get(`/estimates/createRevision/${estimateId}/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.getEstimate(response.data.estimate.id);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getEstimate(id) {
      axios.get(`/estimates/getOne/${id}.json?token=${this.token}`)
        .then((response) => {
          this.estimate = response.data.estimate;
          this.getRates();
          this.getCostDomains();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveLine(finish) {
      if (finish === true && this.newEstimateLine.quantity === 0) {
        this.$router.push(`/job-numbers/index/${this.estimate.client_id}`);
      }
      if (this.validate() === true) {
        const postData = this.newEstimateLine;
        postData.estimate_id = this.estimate.id;
        postData.created_by = this.$store.state.userid;
        axios.post(`/estimateLines/createLine.json?token=${this.token}`, postData)
          .then((response) => {
            this.estimate.estimate_lines.push(response.data.estimateLine);
            let total = 0;
            if (response.data.estimateLine.external === true) {
              total += response.data.estimateLine.cost_each;
            } else {
              total += response.data.estimateLine.lineValue;
            }
            this.estimate.subTotal += total;
            this.newEstimateLine.external = false;
            this.newEstimateLine.personnel = '';
            this.newEstimateLine.quantity = 1;
            this.newEstimateLine.hours = 0;
            this.newEstimateLine.minutes = 0;
            this.newEstimateLine.cost_each = 0;
            this.newEstimateLine.cost_domain_id = 0;
            this.newEstimateLine.description = '';
            this.rateId = 0;
            if (finish === true) {
              this.$router.push(`/job-numbers/index/${this.estimate.client_id}`);
            }
          })
          .catch(() => {
            this.failed = true;
          });
      }
    },
    deleteLine(index) {
      const postData = this.estimate.estimate_lines[index];
      axios.post(`/estimateLines/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.estimate.estimate_lines.splice(index, 1);
        });
    },
    getRates() {
      const clientId = this.estimate.client_id;
      const brandId = this.estimate.brand_id;
      axios.get(`/rateCards/getEstimateRates/${clientId}/${brandId}.json?token=${this.token}`)
        .then((response) => {
          this.rateOptions = response.data.rateOptions;
          this.rates = response.data.rates;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getCostDomains() {
      axios.get(`/costDomains/getAll.json?token=${this.token}`)
        .then((response) => {
          this.costDomains = response.data.domains;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    validate() {
      let result = true;
      let message = '';
      if (this.newEstimateLine.cost_domain_id === 0) {
        result = false;
        message += 'Please select a cost domain. ';
      }
      if (this.newEstimateLine.external === true && this.newEstimateLine.description === '') {
        result = false;
        message += ' Please enter a description.';
      }
      this.message = message;
      if (message.length > 0) {
        this.showMessage = true;
      }
      return result;
    },
  },
  mounted() {
    setTimeout(() => {
      this.createRevision();
    }, 100);
  },
};
</script>
